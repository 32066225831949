import React, { useEffect } from "react";
import { useState } from "react";
import MarkdownIt from "markdown-it";
import { ToastContainer, toast } from "react-toastify";
import MarkdownEditor from "react-markdown-editor-lite";
import { CloseIcon } from "@chakra-ui/icons";
import "react-markdown-editor-lite/lib/index.css";
import { useNavigate } from "react-router-dom";

import {
    FormControl,
    FormLabel,
    FormHelperText,
    Input,
    HStack,
} from "@chakra-ui/react";
import { Tag, TagLabel, TagCloseButton, Button } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import axios from "axios";

function EditTweetC() {
    const { id } = useParams();
    const [data, setData] = useState({});
    const navigate = useNavigate();
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [category, setCategory] = useState("");

    const [handle, setHandle] = useState("");
    const [tags, setTags] = useState([]);
    const [linkToPost, setLinkToPost] = useState("");
    console.log(category, "category");

    const handleEditTweet = async (e) => {
        e.preventDefault();
        try {
            const profileData = JSON.parse(localStorage.getItem("profile"));
            const userToken = profileData?.token;
            const updatedData = {
                title,
                description,
                handle,
                linkToPost,
            };

            const response = await axios.put(
                `${process.env.REACT_APP_API_ENDPOINT}/tweets/editEachTweet/${id}`,
                updatedData,
                {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                }
            );

            setData(response.data.tweet);
            toast.success("Edited successfully!", {
                position: "bottom-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            setTimeout(() => {
                navigate("/admin/tweets");
            }, [2000]);
            console.log("Tweet updated successfully!", response);
        } catch (error) {
            toast.error("Please try again", error, {
                position: "bottom-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            console.error("Error updating tweet:", error);
        }
    };

    useEffect(() => {
        const getEachTweet = async () => {
            const response = await axios.get(
                `${process.env.REACT_APP_API_ENDPOINT}/tweets/getEachTweet/${id}`
            );
            setTitle(response.data.tweets.tweets.title);
            setHandle(response.data.tweets.tweets.handle);
            setLinkToPost(response.data.tweets.tweets.linkToPost);
            setDescription(response.data.tweets.tweets.description);
            setData(response.data.tweets.tweets);
        };
        getEachTweet();
    }, [id]);
    console.log("data", data);
    return (
        <div className="max-w-7xl mx-auto py-[100px] p-10">
            <h2 className="text-center text-[30px] font-bold mb-10">
                Edit tweet
            </h2>

            <form enctype="multipart/form-data" method="put">
                <div>
                    <FormControl isRequired className="mb-6 ">
                        <FormLabel>Title</FormLabel>
                        <Input
                            value={title}
                            type="text"
                            placeholder="title"
                            onChange={(e) => setTitle(e.target.value)}
                        />
                        <FormHelperText>
                            Enter the title of the post.
                        </FormHelperText>
                    </FormControl>
                </div>
                <div>
                    <FormControl isRequired className="mb-6 ">
                        <FormLabel>Handle</FormLabel>
                        <Input
                            value={handle}
                            type="text"
                            placeholder="handle"
                            onChange={(e) => setHandle(e.target.value)}
                        />
                        <FormHelperText>
                            Enter the handle of the post.
                        </FormHelperText>
                    </FormControl>
                </div>
                <div>
                    <FormControl isRequired className="mb-6 ">
                        <FormLabel>Description</FormLabel>
                        <Input
                            value={description}
                            type="text"
                            placeholder="description"
                            onChange={(e) => setDescription(e.target.value)}
                        />
                        <FormHelperText>
                            Enter the description of the post.
                        </FormHelperText>
                    </FormControl>
                </div>
                <div>
                    <FormControl isRequired className="mb-6 ">
                        <FormLabel>Link</FormLabel>
                        <Input
                            value={linkToPost}
                            type="text"
                            placeholder="linkToPost"
                            onChange={(e) => setLinkToPost(e.target.value)}
                        />
                        <FormHelperText>
                            Enter the link of the post.
                        </FormHelperText>
                    </FormControl>
                </div>

                <div></div>

                <Button
                    colorScheme="blue"
                    onClick={handleEditTweet}
                    variant="outline"
                    type="submit"
                    size="lg"
                    className="mt-10"
                >
                    Save tweet
                </Button>
            </form>
        </div>
    );
}

export default EditTweetC;
