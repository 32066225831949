import React from "react";
import { useEffect, useState, useRef } from "react";
import MarkdownIt from "markdown-it";
import ReactMarkdown from "react-markdown";
import twitter from "../../Assets/favicon/twitter.svg";
import discord from "../../Assets/favicon/discord.svg";
import instagram from "../../Assets/favicon/instagram.svg";
import tiktok from "../../Assets/favicon/tiktok.svg";
import "react-markdown-editor-lite/lib/index.css";
import "./EachPost.css";
import remarkGfm from "remark-gfm";
import remarkParse from "remark-parse";
import remarkRehype from "remark-rehype";

import { Spinner } from "@chakra-ui/react";
import { Stack } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import axios from "axios";
import TopStories from "./TopStories";
function EachPostC() {
    const { route } = useParams();
    const [data, setData] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const markdownRef = useRef(null);
    const [body, setBody] = useState("");
    const [headings, setHeadings] = useState([]);
    const mdParser = new MarkdownIt({
        html: true,
        breaks: true,
        linkify: true,
        underline: true,
        highlight: null,
    });

    useEffect(() => {
        const getAllPosts = async () => {
            setIsLoading(true);
            const response = await axios.get(
                `${process.env.REACT_APP_API_ENDPOINT}/post/getEachPost/${route}`
            );

            setBody(response.data.post.body);
            setIsLoading(false);
            setData(response.data.post);
        };
        getAllPosts();
    }, [route]);
    const createdAtDate = new Date(data.createdAt);

    useEffect(() => {
        // Your existing post fetching logic remains the same

        // New logic to extract h2 headings and IDs
        if (markdownRef.current) {
            const extractedHeadings = Array.from(
                markdownRef.current.querySelectorAll("h2")
            ).map((h2, index) => {
                const id = `h2-${index}`; // This matches your ID assignment logic
                h2.id = id; // Ensure the element has the ID (redundant if already set)
                return { id, text: h2.innerText };
            });
            setHeadings(extractedHeadings);
        }
    }, [body]);
    console.log(headings, "headings");

    const smoothScroll = (id, event) => {
        event.preventDefault(); // Prevent default anchor behavior

        const element = document.getElementById(id);
        if (element) {
            const headerOffset = 150; // Adjust this value based on your needs
            const elementPosition = element.getBoundingClientRect().top;
            const offsetPosition =
                elementPosition + window.pageYOffset - headerOffset;

            window.scrollTo({
                top: offsetPosition,
                behavior: "smooth",
            });
        }
    };

    return (
        <div className="flex   flex-col justify-center items-center align-middle bg-transparent mx-auto  w-full ">
            {isLoading ? (
                <Stack direction="row" spacing={4}>
                    <Spinner size="xl" />
                </Stack>
            ) : data ? (
                <div className="w-full px-4 md:px-24  mx-auto md:mb-24">
                    <div className="relative mx-auto     ">
                        <img
                            src={data.cover_photo}
                            alt="coverimage"
                            className="  md:h-[600px] h-[300px] mx-auto w-full object-cover  mb-4 md:mb-24 z-10  "
                        />
                        <div className="md:absolute bottom-0 z-30 px-2 md:px-24  left-0 ">
                            <div className="flex gap-5 align-middle items-center   mb-5">
                                <h5
                                    style={{
                                        fontFamily:
                                            '"AkiraExpanded", sans-serif',
                                    }}
                                    className=" nftprojects"
                                >
                                    NFT PROJECTS
                                </h5>
                                <h5
                                    className="image__text md:text-xl  text-md"
                                    style={{
                                        fontFamily:
                                            '"AkiraExpanded", sans-serif',
                                    }}
                                >
                                    {createdAtDate.toLocaleDateString("en-US", {
                                        year: "numeric",
                                        month: "short",
                                        day: "numeric",
                                    })}
                                </h5>
                            </div>
                            <h1 className="image__text  font-bold md:text-[60px] md:mb-[40px]  md:leading-[80px] text-[20px]   leading-[20px] ">
                                {data.title}
                            </h1>
                        </div>
                    </div>

                    {/* <div className="flex justify-between items-center align-middle  mb-5">
                        <h1 className="text-center font-semi-bold text-[20px] ">
                            {data.caption}
                        </h1>
                    </div> */}

                    {/* <div className="flex gap-4 mb-[100px]">
                        {data.tags &&
                            data.tags.map((item, index) => (
                                <HStack spacing={4}>
                                    <Tag
                                        size={"lg"}
                                        key={index}
                                        borderRadius="full"
                                        variant="solid"
                                        paddingBottom="2px"
                                        colorScheme="blue"
                                    >
                                        <TagLabel>#{item}</TagLabel>
                                    </Tag>
                                </HStack>
                            ))}
                    </div> */}
                    <div className="flex min-h-screen  justify-center relative">
                        <div className="border-r sticky top-[100px] sm:w-[200px] sm:min-w-[200px] mt-[80px]   text-[#3fffff] overflow-y-auto h-screen hidden md:flex md:flex-col">
                            {data.body?.length > 0 &&
                                headings.map((x) => (
                                    <a
                                        key={x.id}
                                        onClick={(e) => smoothScroll(x.id, e)}
                                        className="block hover:underline p-4 text-xs md:text-sm lg:text-md"
                                        href={`#${x.id}`}
                                    >
                                        {x.text}
                                    </a>
                                ))}
                        </div>

                        <div className="flex-1 px-1 md:px-1 max-w-[2200px]  w-full">
                            {data.body?.length > 0 && (
                                <div
                                    ref={markdownRef}
                                    className="gap-10 my-5 markdown-container px-2 sm:px-6 md:px-3  lg:px-[10px] xl:px-[200px]"
                                >
                                    <ReactMarkdown
                                        remarkPlugins={[
                                            remarkGfm,
                                            remarkParse,
                                            remarkRehype,
                                        ]}
                                        children={body}
                                    />
                                </div>
                            )}
                        </div>
                    </div>

                    {/* <div className="flex flex-col items-center align-middle   mx-auto top-[50%] w-fit right-[-20px] md:fixed gap-10 md:p-10">
                        <div className="flex  md:flex-col gap-4 mt-4     items-center align-middle z-50">
                            {" "}
                            <h3 className="text-[12px] md:text-[16px]">
                                SHARE
                            </h3>
                            <a href="">
                                {" "}
                                <img src={discord} alt="discord" />
                            </a>
                            <a href="">
                                {" "}
                                <img src={twitter} alt="twitter" />
                            </a>
                            <a href="">
                                <img src={instagram} alt="instagram" />
                            </a>{" "}
                            <a href="">
                                {" "}
                                <img src={tiktok} alt="tiktok" />
                            </a>
                        </div>
                    </div> */}
                </div>
            ) : (
                <div className="text-center  mx-auto ">
                    {" "}
                    <h1 className="text-center py-[100px]">
                        This post does not exist!
                    </h1>
                </div>
            )}{" "}
            <TopStories />
        </div>
    );
}

export default EachPostC;
