import { useEffect, useState } from "react";

import EditPostC from "../../Components/Post/EditPostC";
import EditTweetC from "../../Components/Tweet/EditTweetC";

function EditTweet() {
    return (
        <div className="max-w-7xl mx-auto justify-center align-middle items-center ">
            <EditTweetC />
        </div>
    );
}

export default EditTweet;
