import React from "react";
import HomeC from "../../Components/Home/HomeC";

function Home() {
    return (
        <div>
            <HomeC />
        </div>
    );
}

export default Home;
