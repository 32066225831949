export const ArrowUpRight = () => {
    return (
        <svg
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M9.16667 0L9.1828 0.000155125C9.20223 0.000529075 9.22166 0.00157915 9.24104 0.00330534L9.16667 0C9.20885 0 9.2503 0.00313433 9.2908 0.00918278C9.30496 0.0113567 9.31948 0.0139185 9.33394 0.0168667C9.35229 0.0205332 9.37036 0.0248614 9.3882 0.0297675C9.40122 0.0334148 9.41404 0.0372739 9.42679 0.0414484C9.44316 0.0467687 9.45965 0.0527631 9.47588 0.0592533C9.49066 0.0651637 9.50508 0.0713992 9.51935 0.0780611C9.53658 0.0861472 9.55358 0.0948283 9.57023 0.104063C9.58033 0.109582 9.59051 0.115506 9.60059 0.121663C9.6219 0.134766 9.64261 0.148742 9.66263 0.163595C9.66884 0.168128 9.67487 0.172737 9.68085 0.17744C9.73349 0.218793 9.78121 0.266515 9.82268 0.319375L9.75592 0.244078C9.78542 0.273573 9.81224 0.304729 9.83639 0.337244C9.85126 0.357391 9.86523 0.378104 9.87828 0.39945C9.88449 0.409489 9.89042 0.419671 9.89611 0.429948C9.90517 0.446422 9.91385 0.463424 9.92196 0.480751C9.9286 0.494918 9.93484 0.509337 9.94065 0.523887C9.94724 0.540351 9.95323 0.556835 9.95871 0.573553C9.96273 0.585957 9.96659 0.598781 9.97013 0.611673C9.97514 0.629636 9.97947 0.647712 9.9832 0.666005C9.98608 0.680521 9.98864 0.695042 9.99082 0.709611C9.99336 0.726229 9.99539 0.743428 9.99689 0.760779C9.9982 0.776445 9.99909 0.791445 9.99957 0.806454C9.99985 0.814824 10 0.824061 10 0.833333V9.16667C10 9.6269 9.6269 10 9.16667 10C8.70643 10 8.33333 9.6269 8.33333 9.16667V2.845L1.42259 9.75592C1.09715 10.0814 0.569515 10.0814 0.244078 9.75592C-0.0813592 9.43049 -0.0813592 8.90285 0.244078 8.57741L7.15333 1.66667H0.833333C0.373096 1.66667 0 1.29357 0 0.833333C0 0.373096 0.373096 0 0.833333 0H9.16667Z"
                fill="white"
            />
        </svg>
    );
};
