import { useState } from "react";
import discord from "../../../Assets/favicon/discord.svg";
import twitter from "../../../Assets/favicon/twitter.svg";
import instagram from "../../../Assets/favicon/instagram.svg";
 import { toast } from "react-toastify";
import x from "../../../Assets/x.svg";
import calibur from "../../../Assets/calibur.svg";
import send from "../../../Assets/send.svg";
import { Link } from "react-router-dom";
import axios from "axios";
export default function NewsLetter() {
    const [email, setEmail] = useState();
    const [data, setData] = useState("");
    const [error, setError] = useState("");
   const handleSubscribe = async () => {
       try {
           const response = await axios.post(
               `${process.env.REACT_APP_API_ENDPOINT}/subscribe/createSubscription`,
               { email }
           );

           setError("");
           setEmail("");
           toast.success("Subscription created!", {
               position: "bottom-center",
               autoClose: 2000,
               hideProgressBar: false,
               closeOnClick: true,
               pauseOnHover: false,
               draggable: true,
               progress: undefined,
               theme: "light",
           });
           console.log("resp", response.data);
       } catch (error) {
           toast.error(error.response.data.message, {
               position: "bottom-center",
               autoClose: 2000,
               hideProgressBar: false,
               closeOnClick: true,
               pauseOnHover: false,
               draggable: true,
               progress: undefined,
               theme: "light",
           });
           console.error("Error fetching posts:", error);
       }
   };
    console.log(data, "data");
    return (
        <div className="my-5 w-full justify-between p-2 md:p-24 flex md:flex-row flex-col bg-black items-center  bgBlue  ">
            <div className="w-full md:w-1/2  homespan  ">
                <span>// like what you see</span>
                <h2
                    className="mt-2 md:mt-5 font-[700] leading-[33px] text-[12px] md:text-[28px] mb-2  md:mb-7"
                    style={{
                        fontFamily: "EurostileExtended",
                    }}
                >
                    Keep up-to-date with weekly insights
                </h2>
                <div className="flex gap-4 mb-5   items-center align-middle">
                    {" "}
                    <a href="Discord.gg/byxcalibur" target="blank">
                        {" "}
                        <img src={discord} alt="discord" />
                    </a>
                    <a href="https://twitter.com/ByXcalibur" target="blank">
                        {" "}
                        <img src={twitter} alt="twitter" />
                    </a>
                    <a
                        href="https://www.instagram.com/byxcalibur/"
                        target="blank"
                    >
                        <img src={instagram} alt="instagram" />
                    </a>{" "}
                    {/* <a href="">
                                {" "}
                                <img src={tiktok} alt="tiktok" />
                            </a> */}
                </div>
            </div>
            <div className="flex md:w-1/2  w-full  justify-end relative">
                <input
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    style={{
                        background:
                            " linear-gradient(0deg, #0F1012, #0F1012), linear-gradient(117.14deg, rgba(255, 255, 255, 0.4) -11.02%, rgba(255, 255, 255, 0) 119.05%)",
                    }}
                    type="email"
                    placeholder="Email"
                    className="placeholder:text-[12px] rounded-none pl-5 p-2 text-[8px] md:text-[12px] w-full md:w-[400px] bg-[#0F1012] border border-solid border-[#FFFFFF66]"
                />
                <div className="absolute left-20 top-10">
                    {" "}
                    {error && (
                        <p className="mt-4 text-red-600 font-bold">{error}</p>
                    )}
                    {data && (
                        <p className="mt-4 text-green-600 font-bold">{data}</p>
                    )}
                </div>

                <button className="  subscribeButton" onClick={handleSubscribe}>
                    <img
                        alt="send "
                        className="md:w-[14px] w-[8px]"
                        src={send}
                    />{" "}
                    Subscribe
                </button>
            </div>
        </div>
    );
}
