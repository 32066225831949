import React from "react";

import AllTweetsTable from "../../Components/Admin/AllTweetsTable";

function AllTweets() {
    return (
        <div className="max-w-7xl mx-auto justify-center align-middle items-center  w-full pt-[100px] ">
            <h1 className="text-center mb-10">Tweets</h1>
            <AllTweetsTable />
        </div>
    );
}

export default AllTweets;
