import React, {useEffect, useState} from "react";
import HearMoreCard from "./card/HearMoreCard";
import axios from "axios";
export default function HearMore() {
    const[loading,setIsLoading]=useState(false)
        const [data, setData] = useState([]);
    // const data = [
    //     {
    //         title: "Magic Eden",
    //         handle: "@MagicEden",
    //         description:
    //             "Introducing Magic Eden Rewards.We’ve spent the last year designing a long term, cross-chain NFT rewards program built for everyone. This isn’t just another points campaign. It’s a meticulously crafted plan to give back to Magic Eden’s OGs, collectors, creators, and degens alike.",
    //         linkToPost:
    //             "https://twitter.com/MagicEden/status/1749870047034790329",
    //     },
    //     {
    //         title: "Crypto Town Hall",
    //         handle: "@Crypto_TownHall",
    //         linkToPost:
    //             "https://twitter.com/Crypto_TownHall/status/1749843244291379374/history",
    //         description:
    //             "BREAKING: ETH ETF COMING — SEC. SEC Commissioner Hester Peirce says the SEC will not make the mistake of delays with Ethereum ETFs. She says the SEC shouldn’t require a court to tell them their approach is arbitrary and capricious for them to get it right. ",
    //     },
    //     {
    //         title: "Solana",
    //         handle: "@solana",
    //         linkToPost: "https://twitter.com/solana/status/1750158439530307870",
    //         description:
    //             "The future of tokenization is now: Introducing token extensions, ready-to-use advanced token functionality on Solana. http://solana.com/tokenextensions .Token extensions empower developers to rapidly build customized token experiences & designed to meet business compliance needs.",
    //     },
    //     // {
    //     //     title: "Kevin Svenson",
    //     //     handle: "@KevinSvenson_",
    //     //     description:
    //     //         "While the masses are distracted by reading the #Epstein Document over the next week, please keep a sharp eye on activities in Washington D.C.They see us as cats chasing a laser pointer.Keep your head on a swivel.#EpsteinFiles #EpsteinList #Doe",
    //     // },
    // ];

    useEffect(() => {
        const getAllTweets = async () => {
            setIsLoading(true);
            try {
                let url = `${process.env.REACT_APP_API_ENDPOINT}/tweets/getAllTweets`;

                const response = await axios.get(url, {});

                setIsLoading(false);

                setData(response.data.tweets.tweets);
                console.log(response.data, "response");
            } catch (error) {
                console.error("Error fetching posts:", error);
            }
        };

        getAllTweets();
    }, []);
 console.log("response",data)

    return (
        <div className="md:pb-[50px]  md:pt-[60px] homespan">
            <span className=" ml-[10px] ">{`// whispers`}</span>
            <HearMoreCard data={data} loading={loading} />
        </div>
    );
}
