import React from "react";

import ArchivedPostsTable from "../../Components/Admin/ArchivedPostsTable";

function ArchivedPosts() {
    return (
        <div className="max-w-7xl mx-auto justify-center align-middle items-center  w-full pt-[100px] ">
            <h1 className="text-center mb-10">Archived Posts</h1>
            <ArchivedPostsTable />
        </div>
    );
}

export default ArchivedPosts;
