import { useEffect, useState } from "react";

import EditPostC from "../../Components/Post/EditPostC";

function EditPost() {

    return (
        <div className="max-w-7xl mx-auto justify-center align-middle items-center ">
            <EditPostC  />
        </div>
    );
}

export default EditPost;
